// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-aktualne-u-nas-tsx": () => import("./../../../src/pages/aktualne-u-nas.tsx" /* webpackChunkName: "component---src-pages-aktualne-u-nas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-rezervace-index-tsx": () => import("./../../../src/pages/rezervace/index.tsx" /* webpackChunkName: "component---src-pages-rezervace-index-tsx" */),
  "component---src-pages-rezervace-zrusit-tsx": () => import("./../../../src/pages/rezervace/zrusit.tsx" /* webpackChunkName: "component---src-pages-rezervace-zrusit-tsx" */),
  "component---src-pages-sluzby-tsx": () => import("./../../../src/pages/sluzby.tsx" /* webpackChunkName: "component---src-pages-sluzby-tsx" */),
  "component---src-pages-souhlas-se-zpracovanim-tsx": () => import("./../../../src/pages/souhlas-se-zpracovanim.tsx" /* webpackChunkName: "component---src-pages-souhlas-se-zpracovanim-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

