/* eslint-disable global-require */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  // const setupMsw = require(`./src/config/setup-msw`);

  // Although it logs "Mocking enabled", interception is not running
  // setupMsw();
};
